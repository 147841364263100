export const checkValidation = (value) => {
    if (value === null || value === undefined || value === "" || value === 0) {
        return "N/A";
    }

    // Convert value to a number if it's a string that contains a number
    const numericValue = typeof value === 'string' && !isNaN(value) ? parseFloat(value) : value;

    // Ensure the value is positive
    if (typeof numericValue === 'number') {
        return Math.abs(numericValue);  // Return the absolute (positive) value
    }

    // If it's not a number, return the original value (assuming it's valid)
    return value;
};


export function formatMessageTime(utcDate) {
    return new Date(utcDate).toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        timeZone: 'UTC',
    });
}


export function capitalizeFirstLetter(string) {
    try {
        if (!string || typeof string !== 'string') {
            return "";
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    } catch (error) {
        return "";
    }
}



export function convertToLabel(text) {
    if (!text) {  // Check if text is undefined, null, or an empty string
        return ''; // Return an empty string or any other default value
    }

    // Split the text at each uppercase letter and join with a space
    const label = text
        .replace(/([A-Z])/g, ' $1')  // Insert space before each capital letter
        .trim();                      // Remove leading/trailing spaces

    // Capitalize the first letter of the result
    return label.charAt(0).toUpperCase() + label.slice(1);
}


// Common Pagination Options Value
export const paginationOptions = [50, 100, 200, 300, 500]
