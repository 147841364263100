import { RETRIEVE_FIRST_USER, RETRIEVE_ASSOCIATED_USER, RETRIEVE_CHATS, CLEAR_CHATS, CLEAR_ASSOCIATED_USERS } from "../actions/types";

const initialState = {
    firstUser: [],
    associatedUser: [],
    roomId: [],
    chats: [],
};

const chatReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case RETRIEVE_FIRST_USER:
            return {
                ...state,
                firstUser: payload,
            };
        case RETRIEVE_ASSOCIATED_USER:
            return {
                ...state,
                associatedUser: payload,
            };
        case RETRIEVE_CHATS:
            return {
                ...state,
                chats: payload,
            };
        case CLEAR_CHATS:
            return {
                ...state,
                chats: [],
            }

        case CLEAR_ASSOCIATED_USERS:
            return {
                ...state,
                associatedUser: [],
            }

        default:
            return state;
    }
};

export default chatReducer;
