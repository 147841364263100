import { useSelector } from "react-redux";
import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState
} from "react";
import { io, Socket } from "socket.io-client";


// Create the context
const SocketContext = createContext(undefined);

// SocketProvider component
export const SocketProvider = ({ children }) => {
    const socketRef = useRef(null);
    const [isConnected, setIsConnected] = useState(false); // Track socket connection status
    const { isLoggedIn: isLogin } = useSelector((state) => state.auth);
    useEffect(() => {
        if (!isLogin) return;
        // Initialize socket connection
        socketRef.current = io(process.env.REACT_APP_API_URL || "", {
            withCredentials: true
        });

        // Set the connection status to true when connected
        socketRef.current.on("connect", () => {
            console.log("Connected to server", socketRef.current?.id);
            setIsConnected(true); // Set connection status to true
        });

        // Cleanup on unmount
        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
                setIsConnected(false);
            }
        };
    }, [isLogin]);

    return (
        <SocketContext.Provider value={{ socket: socketRef.current, isConnected }}>
            {children}
        </SocketContext.Provider>
    );
};

// Hook to use socket context
export const useSocketProvider = () => {
    const context = useContext(SocketContext);
    if (context === undefined) {
        throw new Error("useSocketProvider must be used within a SocketProvider");
    }
    return context; // Return the full context object (with both socket and isConnected)
};