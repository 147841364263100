import {
  RETRIEVE_PURCHASE_ORDERS, RETRIEVE_SELL_ORDERS
} from "../actions/types";

const initialState = {
  purchaseorders: [],
  totalpurchaseOrderCount: 0,
  sellorders: [],
  totalsellOrderCount: 0
};

const ordersReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_PURCHASE_ORDERS:
      return {
        purchaseorders: payload.orderListInfo,
        totalpurchaseOrderCount: payload.count,
      };

      case RETRIEVE_SELL_ORDERS:
      return {
        sellorders: payload.orderListInfo,
        totalsellOrderCount: payload.count,
      };

    default:
      return state;
  }
};

export default ordersReducer;
