export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const CURRENT_USER_UPDATE = "CURRENT_USER_UPDATE";

export const CLEAR_USER = "CLEAR_USER";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SHOW_IMAGE_BACKDROP = "SHOW_IMAGE_BACKDROP";
export const CLOSE_IMAGE_BACKDROP = "CLOSE_IMAGE_BACKDROP";

export const RETRIEVE_USERS = "RETRIEVE_USERS";
export const RETRIEVE_REPORTED_USERS = "RETRIEVE_REPORTED_USERS";
export const RETRIEVE_SINGALE_USER = "RETRIEVE_SINGALE_USER";
export const CREATE_USER = "UPDATE_USER";
export const UPDATE_USER = "CREATE_USER";
export const DELETE_USER = "DELETE USER";
export const RESTORE_USER = "RESTORE_USER";
export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS";
export const ONLINEUSERS = "ONLINEUSERS";

export const RETRIEVE_SELLER = "RETRIEVE_SELLER";
export const RETRIEVE_LOGGEDIN_SELLER = "RETRIEVE_LOGGEDIN_SELLER";
export const CREATE_SELLER = "CREATE_SELLER";
export const UPDATE_SELLER = "UPDATE_SELLER";
export const DELETE_SELLER = "DELETE_SELLER";
export const RESTORE_SELLER = "RESTORE_SELLER";
export const UPDATE_SELLER_STATUS = "UPDATE_SELLER_STATUS";
export const UPDATE_SELLER_ACCOUNT = "UPDATE_SELLER_ACCOUNT";
export const ADD_USER_SUPERADMIN = "ADD_USER_SUPERADMIN";
export const RETRIEVE_USERS_Ad = "RETRIEVE_USERS_Ad";

// export const RETRIEVE_ADD = "RETRIEVE_ADD";

export const RETRIEVE_GAME = "RETRIEVE_GAME";
export const RETRIEVE_GAME_DETAILS = "RETRIEVE_GAME_DETAILS";
export const CREATE_GAME = "CREATE_GAME";
export const UPDATE_GAME = "UPDATE_GAME";
export const DELETE_GAME = "DELETE_GAME";
export const RESTORE_GAME = "RESTORE_GAME";
export const RETRIEVE_SOLD_ORDERS_GAME = "RETRIEVE_SOLD_ORDERS_GAME";

export const CREATE_ROLE = "CREATE_ROLE";
export const RETRIEVE_ROLE = "RETRIEVE_ROLE";
export const CREATE_PERMISSION = "CREATE_PERMISSION";
export const RETRIEVE_PERMISSION = "RETRIEVE_PERMISSION";
export const RETRIEVE_SINGAL_ROLE = "RETRIEVE_SINGAL_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const DELETE_ROLE = "DELETE ROLE";
export const RESTORE_ROLE = "RESTORE_ROLE";

export const RETRIEVE_DISPUTE = "RETRIEVE_DISPUTE";
export const CREATE_DISPUTE = "CREATE_DISPUTE";
export const RETRIEVE_DISPUTE_DETAILS = "RETRIEVE_DISPUTE_DETAILS";
export const UPDATE_DISPUTE_STATUS = "UPDATE_DISPUTE_STATUS";
export const DELETE_DISPUTE = "DELETE_DISPUTE";
export const RESTORE_DISPUTE = "RESTORE_DISPUTE";
//  Roles and Permision API

export const UPDATE_ROLES_STATUS = "UPDATE_ROLES_STATUS";

export const RETRIEVE_RULES = "RETRIEVE_RULES";
export const CREATE_RULES = "CREATE_RULES";
export const UPDATE_RULES = "UPDATE_RULES";
export const DELETE_RULES = "DELETE_RULES";
export const UPDATE_RULES_STATUS = "UPDATE_RULES_STATUS";
export const RESTORE_RULES = "RESTORE_RULES";

export const RETRIEVE_FAQ = "RETRIEVE_FAQ";
export const CREATE_FAQ = "CREATE_FAQ";
export const UPDATE_FAQ = "UPDATE_FAQ";
export const DELETE_FAQ = "DELETE_FAQ";
export const UPDATE_FAQ_STATUS = "UPDATE_FAQ_STATUS";
export const RESTORE_FAQ = "RESTORE_FAQ";

export const RETRIEVE_BANNER = "RETRIEVE_BANNER";
export const CREATE_BANNER = "CREATE_BANNER";
export const UPDATE_BANNER = "UPDATE_BANNER";
export const DELETE_BANNER = "DELETE_BANNER";
// export const UPDATE_BANNER_STATUS = "UPDATE_BANNER_STATUS";
export const RESTORE_BANNER = "RESTORE_BANNER";

export const CREATE_INSURANCE = "CREATE_INSURANCE";
export const UPDATE_INSURANCE = "UPDATE_INSURANCE";
export const DELETE_INSURANCE = "DELETE_INSURANCE";
// export const UPDATE_INSURANCE_STATUS = "UPDATE_INSURANCE_STATUS";
export const RESTORE_INSURANCE = "RESTORE_INSURANCE";
export const RETRIEVE_INSURANCE = "RETRIEVE_INSURANCE";
// Booster Plan
export const RETRIEVE_BOOSTERPLAN = "RETRIEVE_BOOSTERPLAN";
export const UPDATE_BOOSTERPLAN = "UPDATE_BOOSTERPLAN";
export const DELETE_BOOSTERPLAN = "DELETE_BOOSTERPLAN";
export const RESTORE_BOOSTERPLAN = "RESTORE_BOOSTERPLAN";
export const CREATE_BOOSTERPLAN = "CREATE_BOOSTERPLAN";

export const RETRIEVE_CMS = "RETRIEVE_CMS";
export const UPDATE_CMS = "UPDATE_CMS";
export const DELETE_CMS = "DELETE_CMS";
export const RESTORE_CMS = "RESTORE_CMS";
export const CREATE_CMS = "CREATE_CMS";

export const RETRIEVE_PURCHASE_ORDERS = "RETRIEVE_PURCHASE_ORDERS";
export const RETRIEVE_SELL_ORDERS = "RETRIEVE_SELL_ORDERS";
export const RETRIEVE_TRADE_ORDERS = "RETRIEVE_TRADE_ORDERS";


export const RETRIEVE_SUPPORT_REQUEST = "RETRIEVE_SUPPORT_REQUEST";

export const RETRIEVE_FIRST_USER = "RETRIEVE_FIRST_USER";
export const RETRIEVE_ASSOCIATED_USER = "RETRIEVE_ASSOCIATED_USER";
export const RETRIEVE_ROOM_ID = "RETRIEVE_ROOM_ID";
export const RETRIEVE_CHATS = "RETRIEVE_CHATS";
export const CLEAR_CHATS = "CLEAR_CHATS";
export const CLEAR_ASSOCIATED_USERS = "CLEAR_ASSOCIATED_USERS"

export const RETRIEVE_SERVICE_CHARGE = "RETRIEVE_SERVICE_CHARGE"
export const CREATE_SERVICE_CHARGE = "CREATE_SERVICE_CHARGE"

export const RETRIEVE_TRANSACTIONS = "RETRIEVE_TRANSACTIONS";