import {
  RETRIEVE_GAME,
  CREATE_GAME,
  UPDATE_GAME,
  DELETE_GAME,
  RESTORE_GAME,
  RETRIEVE_SOLD_ORDERS_GAME
} from "../actions/types";
import CryptoJS from "crypto-js";
import forge from "node-forge";


export const gameDescription = (data) => {
  // console.log(data)
  const { encryptedAESKey, gameDetails, iv } = data;
  // console.log(encryptedAESKey, gameDetails, iv )
  if (!encryptedAESKey || !gameDetails || !iv) {
    console.error("Missing one or more required cookies: encryptedAESKey, gameDetails, IV");
    return null;
  }

  const decryptAESKeyWithRSA = (encryptedAESKey) => {
    try {
      const privateKey = forge.pki.privateKeyFromPem(process.env.REACT_APP_PRIVATE_KEY);
      const encryptedBuffer = forge.util.decode64(encryptedAESKey);
      // debugger
      const decryptedKey = privateKey.decrypt(encryptedBuffer, "RSA-OAEP");
      return forge.util.encode64(decryptedKey);
    } catch (error) {
      console.error("Error decrypting AES key with RSA:", error);
      return null;
    }
  };

  const decryptWithAES = (gameDetails, key, iv) => {
    try {
      const decrypted = CryptoJS.AES.decrypt(
        gameDetails,
        CryptoJS.enc.Base64.parse(key),
        {
          iv: CryptoJS.enc.Base64.parse(iv),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      return JSON.parse(CryptoJS.enc.Utf8.stringify(decrypted));
    } catch (error) {
      console.error("Error decrypting permission data with AES:", error);
      return null;
    }
  };

  const DECRYPTED_ASE_KEY = decryptAESKeyWithRSA(encryptedAESKey);
  if (!DECRYPTED_ASE_KEY) {
    return null;
  }

  const DECRYPTED_PERMISSION = decryptWithAES(gameDetails, DECRYPTED_ASE_KEY, iv);
  if (!DECRYPTED_PERMISSION) {
    return null;
  }
  return DECRYPTED_PERMISSION;
};


const initialState = { games: [], soldGames: [], totalGameCount: 0 };

const gamesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_GAME:
      return {
        ...state,
        games: payload.rows,
        totalGameCount: payload.count,
      };

    case CREATE_GAME:
      return payload;

    case UPDATE_GAME:
      return payload;

    // case UPDATE_SELLER_STATUS:
    //   const updated = state.sellers?.map((item) =>
    //     item.id === payload.id ? { ...item, ...payload } : item
    //   );
    //   return {
    //     sellers: updated,
    //     totalSellerCount: state.totalSellerCount,
    //   };

    case DELETE_GAME:
      // const updatedGames = state.games?.map((item) =>
      //   item.publicId === payload.publicId ? { ...item, ...payload } : item
      // );
      return {
        ...state,
        games: payload,
        totalGameCount: state.totalGameCount,
      };

    case RESTORE_GAME:
      const restored = state.games?.map((item) =>
        item.publicId === payload.publicId ? { ...item, ...payload } : item
      );
      return {
        games: restored,
        totalGameCount: state.totalGameCount,
      };


    case RETRIEVE_SOLD_ORDERS_GAME:
      return {
        ...state,
        soldGames: payload,
        // totalGameCount: state.totalGameCount,
      };
    default:
      return state;
  }
};

export default gamesReducer;
