import { RETRIEVE_TRANSACTIONS } from "../actions/types";

const initialState = { transaction: [], totalTransactionCount: 0, adminCoinDetails: {}, };


const transactionReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case RETRIEVE_TRANSACTIONS:
            return {
                transaction: payload.transactionListInfo,
                totalTransactionCount: payload.count,
                adminCoinDetails: {
                    ...state.adminCoinDetails,
                    adminTotalCoins: payload.adminTotalCoins,
                    adminWithdrawableCoins: payload.adminWithdrawableCoins
                }
            };
        default:
            return state;
    }
};

export default transactionReducer;
