import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const SuperAdminCheck = ({ permission }) => {
  
  // To Identify User as Super Admin Based on RoleID
  if (permission[0]?.roleId !== 1) return <Navigate to={"/forbidden"} />

  return (
    <Outlet />
  )
}

export default SuperAdminCheck