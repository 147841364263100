import { authHeader, multipartHeader } from "./auth-header";
import axiosInstance from "./axiosInstance"


const getAll = (param) => {
  return axiosInstance.Auth.get(process.env.REACT_APP_API_URL + "faqs/list", {
    headers: authHeader(),
    params: param,
  });
};

const get = (id) => {
  return axiosInstance.Auth.get(process.env.REACT_APP_API_URL + `faq/view/${id}`, {
    headers: authHeader(),
  });
};

const create = (data) => {
  return axiosInstance.Auth.post(process.env.REACT_APP_API_URL + "faq/create", data, {
    headers: authHeader(),
    params: {},
  });
};

const deleteFaq = (id, data) => {
  return axiosInstance.Auth.delete(
    `${process.env.REACT_APP_API_URL}faq/delete/${id}`,
    {
      headers: authHeader(),
      data: data,
    }
  );
};

// restore
const restore = (id, data) => {
  return axiosInstance.Auth.post(
    `${process.env.REACT_APP_API_URL}faq/restore/${id}`, data,
    {
      headers: authHeader(),
    }
  );
};

const update = (id, data) => {
  return axiosInstance.Auth.put(`${process.env.REACT_APP_API_URL}faq/update/${id}`, data, {
    headers: authHeader(),
  });
};

const statusUpdate = (id, Active, param) => {
  return axiosInstance.Auth.put(process.env.REACT_APP_API_URL + `faq/updateStatus/${id}`, {
    "isActive": Active,
    ...param
  }, {
    headers: authHeader(),
  });
};


export default {
  getAll,
  create,
  deleteFaq,
  restore,
  update,
  statusUpdate,
  get,
}