import {
  RETRIEVE_USERS,
  RETRIEVE_SINGALE_USER,
  UPDATE_USER,
  UPDATE_USER_STATUS,
  RESTORE_USER,
  DELETE_USER,
  CREATE_USER,
  ADD_USER_SUPERADMIN,
  RETRIEVE_USERS_Ad,
  RETRIEVE_REPORTED_USERS,
  ONLINEUSERS,
} from "../actions/types";

const initialState = { users: [], totalUserCount: 0, totalActiveUsers: 0, activeList: [], activeListCount: 0, reportedUsers: [], reportedUsersCount: 0, onlineUsers: 0 };

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RETRIEVE_USERS:
      return {
        ...state,
        users: payload.rows,
        totalUserCount: payload.count,
        totalActiveUsers: payload.totalActiveUsers
      };

    case RETRIEVE_REPORTED_USERS:
      return {
        ...state,
        reportedUsers: payload.rows,
        reportedUsersCount: payload.count,
      };

    case RETRIEVE_USERS_Ad:
      return {
        activeList: payload.rows,
        activeListCount: payload.count,
      };

    case RETRIEVE_SINGALE_USER:
      return payload;

    case CREATE_USER:
      return payload;

    case UPDATE_USER:
      return payload;

    case UPDATE_USER_STATUS:
      return {
        users: payload,
        totalUserCount: state.totalUserCount,
      };

    case DELETE_USER:
      return {
        ...state,
        users: payload,
        totalUserCount: state.totalUserCount,
      };

    case RESTORE_USER:
      return {
        users: payload,
        totalUserCount: state.totalUserCount,
      };

    case ADD_USER_SUPERADMIN:
      return {
        ...state,
      };


    case ONLINEUSERS:
      return {
        ...state,
        onlineUsers: payload
      };


    default:
      return state;
  }
};

export default userReducer;
